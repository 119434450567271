import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layouts/Default"
import Header from "../../components/common/Header"
import SvgFeature from "../../components/common/SvgFeature"
import { graphql } from "gatsby"
import Reviews from "../../components/Reviews"
import FeatureSection from "../../components/containers/FeatureSection"
import ContentTwoColumns from "../../components/containers/ContentTwoColumns"
import ContentTextbox from "../../components/common/ContentTextbox"
import CtaSection from "../../components/common/CtaSection"
import illustrationsVacation from "./../../data/img/illustrations/vacation.svg"
import H1 from "../../components/typography/H1"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  const anfrageLink = "/finanzierungen/privatkredit/anfragen/"

  const svg = {
    finance: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="7" y="9" width="14" height="10" rx="2" />
        <circle cx="14" cy="14" r="2" />
        <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
      </svg>
    ),
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    ),
  }

  return (
    <Layout
      title="Privatkredit Kassel: wir finden Deinen Niedrigzins"
      description="Du suchst einen Privatkredit in Kassel? Wir vergleichen bei nahezu allen deutschen Banken, um den besten Kredit zu finden."
      location={location}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
      imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
    >
      <Header
        link={anfrageLink}
        linktext="Jetzt Privatkredit finden"
        image={data.header}
        alt="Privatkredit Kassel: Paar im Urlaub isst Pizza"
      >
        <div className="text-sm font-semibold tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Finanzierungen
        </div>
        <H1 className="mt-1">
          <span className="text-blue-500">Privatkredit Kassel:</span> wir finden
          und vergleichen Deinen Niedrigzins
        </H1>

        <div className="inline-flex my-8 ">
          <Link to={anfrageLink}>
            <SvgFeature svg={svg.finance} title="Privatkredit mit Best-Zins" />
          </Link>
        </div>
        <P>
          Plötzlich und unerwartet gibt Deine Waschmaschine ihren Geist auf,
          Dein Auto macht Probleme und zusätzlich muss der geplante Urlaub
          ebenfalls noch finanziert werden. Glücklicherweise sind in den letzten
          Jahren durch die massive Zinssenkung der Europäischen Zentralbank
          Privatkredite deutlich günstiger geworden und stellen für viele
          Verbraucher eine passende Möglichkeit der schnellen Finanzierung von
          geplanten oder auch notwendigen Anschaffungen dar. Aber auch eine
          Umschuldung eines älteren Kredites mit höheren Zinsen macht Sinn.
        </P>
      </Header>

      <FeatureSection title="Was uns besonders macht">
        <SvgFeature
          svg={svg.check}
          title="Top-Zins durch Vergleich"
          text="Wir vergleichen unabhängig bei ca. 30 Banken."
        />
        <SvgFeature
          svg={svg.check}
          title="Bereitstellung Deiner Dokumente"
          text="Wir sind kein Vergleichs-Tool. Wir helfen Dir bei der Bereitstellung der Dokumente."
        />
        <SvgFeature
          svg={svg.check}
          title="Umschulden und Zusammenfassen von offenen Krediten"
          text="Spare Geld durch den aktuellen Niedrig-Zins."
        />
        <SvgFeature
          svg={svg.check}
          title="Flexible Kredite in Höhe von 5.000 € bis 70.000 €"
          text="Alle Kredite mit einer Wunschlaufzeit von 24 bis 120 Monaten und Sondertilgung sind jederzeit möglich."
        />
        <SvgFeature
          svg={svg.check}
          title="Optionaler Schutz"
          text="Mit optionalem Schutz gegen Tod, Arbeitsunfähigkeit und Arbeitslosigkeit."
        />
        <SvgFeature
          svg={svg.check}
          title="Kostenfreie Beratung"
          text="Wir beraten Dich natürlich kostenlos. Du zahlst nur die Raten zu Deinem Kredit."
        />
      </FeatureSection>

      <ContentTwoColumns>
        <ContentTextbox title="Warum lohnt sich ein Vergleich von Privatkrediten?">
          <p>
            Wusstest Du, dass Du bei der eigenen Hausbank, obwohl Du
            langjähriger Kunde bist, nicht immer den günstigsten Kredit
            bekommst?{" "}
            <strong>
              Deshalb lohnt sich ein Vergleich bei unterschiedlichen Banken,
              damit Du nicht zu viel für Deinen Kredit bezahlst.
            </strong>{" "}
            Hinzu kommt, dass beim Privatkredit gegenüber anderen
            Finanzierungsformen, diese ganz ohne Sicherheiten vergeben werden
            können. Das ist ein großer Vorteil im Gegensatz zu
            Hypothekendarlehen. Damit es bei der Bank auch klappt, stellen wir
            natürlich bei Bedarf alle nötigen Dokumente mit Dir zusammen.
          </p>
          <img
            src={illustrationsVacation}
            alt="Privatkredit Kassel: Illustration mit Personen im Urlaub"
            title="Privatkredit Kassel: Illustration mit Personen im Urlaub"
            className="w-9/12 mx-auto"
          />
        </ContentTextbox>
        <ContentTextbox title="Wozu kann ein Privatkredit verwendet werden?">
          <p>
            Eine Finanzierung dient im Regelfall als Lösung bei unvorhersehbaren
            Reparaturen, einer größeren Anschaffung oder Erfüllung von Wünschen
            wenn das Ersparte mal nicht ausreicht. Zahlreiche Händler und
            Geschäfte bieten den Ratenkredit inzwischen ebenfalls als gängige
            Zahlungsmethoden an.{" "}
            <strong>
              Was allerdings viele nicht wissen – ein Privatkredit kann zum
              Beispiel auch zur Finanzierung eines Autos genutzt werden. Die
              Idee dahinter ist simpel und einfach: Oft geben Dir Autohäuser bei
              Barkäufen deutlich bessere Konditionen, als wenn Du es vor Ort
              finanzierst.
            </strong>{" "}
            Was liegt also näher, als die Finanzierung des neuen Autos über
            einen Privatkredit vorzunehmen und dabei auch noch bares Geld zu
            sparen.
          </p>
          <p>
            Eine weitere Möglichkeit für die Nutzung des Privatkredits ist die{" "}
            <strong>
              Umschuldung von einem teuren Dispokredit hin zu einer deutlich
              günstigeren Finanzierung.
            </strong>{" "}
            Das ergibt immer dann Sinn, wenn das Girokonto den überwiegenden
            Teil des Monats im Minus steht und auf den ausstehenden Teil hohe
            Dispozinsen zu zahlen sind. Häufig sind Zinsunterschiede von 8
            Prozent und mehr keine Seltenheit und bedeuten bei einer
            durchschnittlichen Überziehung von 1.000 Euro ca. 80 Euro Mehrkosten
            – Jahr für Jahr. Hier ist ein Privatkredit eine kostengünstige
            Lösung, die Dir am Ende auch die finanzielle Freiheit zurückgibt.
          </p>
          <p>
            Ein weiterer guter Grund für einen Privatkredit ist die Bündelung
            von Krediten. Wer beispielsweise den Dispo ständig ausreizt,
            parallel noch einen Ratenkredit für ein Haushaltsgerät bedient und
            vielleicht an anderer Stelle weitere Verpflichtungen besitzt, droht
            schnell den Überblick zu verlieren.{" "}
            <strong>
              Hinzu kommt, dass jeder weitere Kredit aus Sicht der Bank ein
              Risiko darstellt und demzufolge die Konditionen für den neuen
              weiteren Kredit immer teurer werden lässt. Warum also nicht die
              unterschiedlichen Kredite bündeln?
            </strong>{" "}
            Mit unserer Hilfe findest Du in vielen Fällen günstigere Angebote.
            Du hast nur noch eine Abbuchung im Monat und behältst langfristig
            den Überblick. Und für den Fall, dass dann doch ein weiterer Kredit
            notwendig wird, helfen wir Dir auch hierbei den bestehenden Kredit
            eventuell anzupassen.
          </p>
        </ContentTextbox>
      </ContentTwoColumns>

      <CtaSection
        title1="Privatkredit mit Best-Zins finden: mit gemeinsamer Bereitstellung der Dokumente"
        text="Wir finden Deinen günstigsten Privatkredit."
        link1={anfrageLink}
        link1text="Jetzt Privatkredit finden"
      />
      <ContentTwoColumns>
        <ContentTextbox title="Was unterscheidet uns von anderen Vergleichsplattformen?">
          <p>
            Wir möchten nicht nur ein Portal sein, auf dem Du Deine Daten
            eingibst und ein Ergebnis erhältst. Die Idee hinter unserem Service
            ist die ständige Aktualisierung von Angeboten der Kreditinstitute.
            Wir sind dabei immer auf dem neuesten Stand und kennen meist die
            neuesten Angebote noch bevor diese auf der Website der Bank
            veröffentlicht wurden.{" "}
            <strong>
              Dazu kennen wir auch die Vergabepraxis der Kreditinstitute ganz
              genau. Nicht selten sind beispielsweise Lockangebote mit
              Minuszinsen zu finden, die nur Kunden mit besonders guter Bonität
              erhalten.
            </strong>{" "}
            Wir wissen aus langjähriger Erfahrung, welche Bank wie im
            Hintergrund arbeitet und welche Voraussetzungen gegeben sein müssen,
            um am Ende die optimalen Konditionen zu erhalten.{" "}
            <strong>
              Wir finden Dir nicht nur den Kredit mit Top-Konditionen, sondern
              bieten Dir auch Unterstützung bei der Antragsstellung. Denn häufig
              sind es kleine Details, die bei der Kreditprüfung den Ausschlag
              geben und im schlimmsten Fall sogar zur Ablehnung führen.
            </strong>
          </p>
        </ContentTextbox>
        <ContentTextbox title="Wie sieht unser Service genau aus?">
          <p>
            Wir verstehen uns als Dein Partner. Wir nehmen Dich vom ersten
            Moment an die Hand, prüfen mit Dir gemeinsam welche Kreditsumme und
            Laufzeit infrage kommt, bereiten die besten Angebote auf und gehen
            mit Dir Schritt für Schritt durch den Antragsprozess. Eventuelle
            Unstimmigkeiten decken wir für Dich auf, damit es nicht zu
            ungewollten Rückfragen durch die Bank kommt. Wir reichen immer
            gemeinsam mit Dir die Antragsunterlagen ein und Du kannst Dich
            darauf verlassen, dass diese vollständig und schlüssig sind.{" "}
            <strong>
              Sollte es doch einmal zu Rückfragen seitens der Bank kommen,
              lassen wir dich auch hier nicht allein. Uns ist es wichtig das vom
              Erstkontakt bis zur finalen Auszahlung keine Bauchschmerzen bei
              Dir entstehen. Gerade dieser Service ist erfahrungsgemäß von
              besonderer Bedeutung, denn bei vielen Kreditnehmern ist eine
              zeitnahe Auszahlung wichtig.
            </strong>{" "}
            Wird diese durch fehlende Unterlagen verzögert, können notwendige
            Anschaffungen oder Reparaturen nicht durchgeführt werden.
          </p>
        </ContentTextbox>
      </ContentTwoColumns>
      <ContentTwoColumns>
        <ContentTextbox title="Gibt es verstecke Kosten?">
          <p>
            Nein. Bei uns wird Dir ausschließlich der gewünschte Privatkredit
            vermittelt. Dies ist leider nicht selbstverständlich. Wir mussten in
            der Vergangenheit des Öfteren die Erfahrung machen, dass eine
            Vielzahl unserer Kunden, die uns zur Umschuldung aufsuchten, vom
            Anbieter bei ihren bestehenden Kreditverträgen auch unwissentlich
            Versicherungsverträge vermittelt bekamen. Diese Verträge werden
            oftmals in die Rate einkalkuliert und sind als versteckte Kosten
            nicht immer auf den ersten Blick erkennbar. Wie Du Dir sicherlich
            vorstellen kannst steigt dadurch die Provision des Anbieters, aber
            nur selten der Mehrwert des Kreditnehmers. Wir agieren vollständig
            unabhängig und sind an kein Kreditinstitut gebunden. Mit dieser
            Unabhängigkeit unterstützen wir ausschließlich Deine Interessen und
            nutzen den Marktvergleich.
          </p>
          <p>
            Natürlich ist es in einigen Situationen dennoch sinnvoll eine
            Absicherung abzuschließen. Wir möchten voll und ganz transparent
            sein und offenlegen, welche Optionen Du hast und was wir Dir auf
            Grund unserer Erfahrung empfehlen würden. Dies ist allerdings nur
            eine Option, so dass die Entscheidung ausschließlich bei dir liegt.{" "}
            <strong>Bei uns gibt es keine versteckten Kosten.</strong>
          </p>
        </ContentTextbox>
      </ContentTwoColumns>
      <Reviews />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    header: file(
      relativePath: { eq: "headers/dierda_privatkredit_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    seoImage: file(
      relativePath: { eq: "headers/dierda_privatkredit_header.jpg" }
    ) {
      facebook: childImageSharp {
        gatsbyImageData(height: 630, width: 1200)
      }
      twitter: childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }
  }
`
